.swiper-container {
  position: relative;
}

.swiper-wrapper{
  padding-top: 30px;
  padding-bottom: 15px;
}

.custom-navigation-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.custom-navigation-buttons .nav-buttons {
  padding: 10px;
  background-color: #F5F5F5;
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  z-index: 100;
}

.product-top-right-badge{
  font-family: 'Aileron';
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.swiper-pagination{
  position: absolute;
}