.sub-menu-icon {
  cursor: pointer;
  height: 51px;
}
.sub-menu-icon + .sub-menu-input {
  display: none;
}
.sub-menu-icon + .sub-menu-input + .sub_menu {
  top: -600px;
}
.sub-menu-icon + .sub-menu-input:checked + .sub_menu {
  top: 73px;
}

.sub_menu {
  position: absolute;
  right: 0;
  background: rgb(0, 0, 0);
  border-radius: 30px;
  padding: 10px;
  z-index: 99;
  max-width: 300px;
  width: 100%;
  transition: 0.8s ease-in;
}
.sub_menu ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
}
.sub_menu ul li {
  width: 48%;
  margin: 0 !important;
  text-align: center;
  margin: 0 0 25px !important;
}
.sub_menu ul li a {
  display: block;
  color: #fff;
  text-decoration: none;
}
.sub_menu ul li a:hover {
  color: #3ec1ed;
}
.sub_menu ul li a figure {
  min-height: 50px;
}
.sub_menu ul li a img {
  width: 56px;
  display: block;
  margin: 0 auto 10px;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}
.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}
