#treatmentDetail .MuiPaper-elevation{
    border-radius: 25px;
    height: 500px;
    overflow: hidden;
}
#treatmentDetail button
{
    margin-right: 5px;
    margin-top: 5px;
}
#treatmentDetail .prdct_dtl{
font-size: 18px;
font-weight: 600;
overflow-y: scroll;
overflow-x: hidden;
height: 370px;
}
#treatmentDetail .prdct_dtl p{
   font-size: 16px;
   font-weight: 400; 
   line-height: 24px;
}
#treatmentDetail .prdct_dtl ul li{
    font-size: 16px;
    font-weight: 400; 
    line-height: 24px;
}
#treatmentDetail .video-hldr{
    padding: 0 20px;
}
#treatmentDetail .video-hldr iframe{
    height: 375px !important;
    width: 100%;
}
.prdct_dtl iframe{
    display: none;
}
@media screen and (max-width: 500px) {
    #treatmentDetail .MuiPaper-elevation{
        height: auto;
    }
    #treatmentDetail .video-hldr{
        padding: 0;
    }
    #treatmentDetail .video-hldr iframe{
        height: 230px !important;
   
    }
    #treatmentDetail .prdct_dtl img{
        width: 100% !important;
    }
  }


