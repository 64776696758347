.error-container{
    background-color: #FFF1F2;
    border-radius: 20px;
    margin: 0px 0 30px 64px;
    padding: 30px;
    text-align: left;
}
.error-container .error-title{
    color: #000;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
}
.error-highlight:before{
    content: url(https://evidentdigital.com/wp-content/uploads/2024/06/alert-circle.svg);
    margin-right: 10px;
    position: relative;
    top: 5px;
}
.disabledbox{
    pointer-events: none;
    opacity: 0.5;
}
.error-title:before{
    content: url(https://evidentdigital.com/wp-content/uploads/2024/06/alert-circle.svg);
    margin-right: 10px;
    position: relative;
    }
.error-container .treatment-description{
    color: #DD0C0C;
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0 10px 30px;
}
#proceed:disabled{
    pointer-events: none;
    opacity: 0.5;
}
.error-container .treatment-description span:before{
    content: "●";
    font-size: 12px;
    color: #DD0C0C;
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.error-container .treatment-description span{
    position: relative;
    background-color: #FFF1F2;
    z-index: 9999;
    padding-right: 10px;
}

.error-container .treatment-description a{
    color: #1A64D3;
    float: right;
    position: relative;
    background-color: #FFF1F2;
    z-index: 9999;
    padding-left: 10px;
}
.error-container .treatment-description:after {
    content: " ";
    height: 1px;
    width: 100%;
    display: block;
    border-bottom: 1px dashed #BCBCBC;
    position: relative;
    top: -10px;
}